<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <div v-if="form.examine_state===1">
                        审核中，预计需要一个工作日<br />
                        审核通过后，需要退出重新登录<br />
                    </div>
                    <el-form v-else v-loading="loading" size="medium" ref="form" :model="form"
                        :label-width="this.env.label_width">
                        <el-form-item label="名称">
                            <el-input v-model="form.company_name"></el-input>
                        </el-form-item>
                        <el-form-item label="Logo">
                            <Qnupload v-model="form.logo" :sum="1" :isclipper="true" :fixedNumber="[1,1]" />
                            <span class="form-tip">图片尺寸 宽:高≈1:1(非必选)</span>
                        </el-form-item>
                        <el-form-item label="类型" prop="sex">
                            <el-radio-group v-model="form.type">
                                <el-radio :label="1">商户</el-radio>
                                <el-radio :label="2">个人</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="地址选择">
                            <section>
                                <div style="width:40%;display: inline-block;">
                                    <Region ref="region" v-model="form.region_code" v-on:region="getregion" />
                                </div>
                                <div style="width:60%;display: inline-block;">
                                    <el-input style="width:100%" v-model="form.address"></el-input>
                                </div>
                            </section>
                            <p style="margin:0">{{`${form.province}${form.city}${form.county}${form.address}`}}</p>
                        </el-form-item>
                        <el-form-item label="联系人姓名">
                            <el-input v-model="form.company_contacts"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人电话号码">
                            <el-input v-model="form.company_contacts_mobile"></el-input>
                        </el-form-item>

                        <el-form-item label="企业风采照">
                            <Qnupload v-model="form.photo" :sum="5" :isclipper="true" :fixedNumber="[4,3]" />
                            <span class="form-tip">图片尺寸 宽:高≈4:3</span>
                        </el-form-item>

                        <el-form-item label="统一社会信用代码/身份证" label-width="180px">
                            <el-input v-model="form.uniform_social_credit_code"></el-input>
                            <p style="color:#fc142f">企业请输入统一社会信用代码，个人请输入身份证号码</p>
                        </el-form-item>
                        <el-form-item label="营业执照/身份证">
                            <Qnupload v-model="form.business_license" :sum="2" />
                            <p style="color:#fc142f">企业请上传营业执照，个人请上传身份证</p>
                        </el-form-item>
                        <el-form-item label="银行卡号">
                            <el-input v-model="form.bank_card_no" :maxlength="100"></el-input>
                        </el-form-item>
                        <el-form-item label="开户名">
                            <el-input v-model="form.bank_card_name" :maxlength="100"></el-input>
                        </el-form-item>
                        <el-form-item label="开户行">
                            <el-input v-model="form.bank_of_deposit" :maxlength="100"></el-input>
                        </el-form-item>
                        <!--                        <el-form-item label="租房合同">-->
                        <!--                            <Qnupload v-model="form.house_contract_img" :sum="3"/>-->
                        <!--                        </el-form-item>-->
                        <el-form-item label="简介">
                            <!-- 富文本编辑框 -->
                            <Wangeditor ref="wangeditor" v-model="form.content" idindex="1" seewWidth="50%" />
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="is_auth('systemset.company.applyexamine') && form.examine_state!==1"
                                size="medium" type="primary" @click="applyexamine">提交申请
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'
import Region from '@/components/Region'

export default {
    components: {
        Wangeditor,
        Qnupload,
        Region,
    },
    data() {
        return {
            page_name: '商户信息',
            loading: true,
            company_uuid: '',
            form: {
                company_name: '',
                logo: '',
                company_contacts: '',
                company_contacts_mobile: '',
                province: '',
                city: '',
                county: '',
                address: '',
                type: 1,
                uniform_social_credit_code: '',
                business_license: [],
                content: '',
                region_code: '',

                bank_card_no: '',
                bank_card_name: '',
                bank_of_deposit: '',
                house_contract_img: [],
                photo: [],
            },
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.getinfo();
        },
        // 商户详情
        getinfo() {
            let postdata = {
                api_name: "systemset.company.getinfo",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                    this.$refs.wangeditor.append(json.data.content);
                    this.$refs.region.init(json.data.region_code);
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 申请审核
        applyexamine() {
            let postdata = {
                api_name: "systemset.company.applyexamine",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        onClose: () => {
                            this.getinfo();
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 地址
        getregion(region_date) {
            this.form.province = region_date.province.region_level
            this.form.city = region_date.city.region_level
            this.form.county = region_date.county.region_level
        },
        // 返回
        isreturn() {
            this.$router.push({ path: '/company/company' })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
